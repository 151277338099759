import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const TriangleImage = styled.img`
  position: absolute;
  top: -677px;
  right: -348px;
  ${media.sm} {
    top: -144px;
  }
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 1.6;
  color: #838791;
  margin: 0;
  white-space: pre-wrap;
  ${media.sm} {
    font-size: 14px;
    line-height: 1.43;
    white-space: initial;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  font-weight: normal;
  line-height: 1.31;
  margin-bottom: 25px;
  ${media.sm} {
    font-size: 32px;
    line-height: 1.38;
    margin-bottom: 4px;
  }
  strong {
    font-weight: normal;
    color: #5871f4;
  }
`;

export const ContentLeft = styled.div`
  padding-top: 103px;
  ${media.md} {
    padding-top: 0;
  }
`;

export const ContentRight = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  ${media.lg} {
    padding: 60px 0 0;
  }
  ${media.md} {
    display: flex;
    justify-content: center;
  }
  .illustration-wrapper {
    position: relative;
    height: 100%;
    width: 600px;
    ${media.lg} {
      height: 235px;
      width: 480px;
    }
    ${media.xs} {
      width: 320px;
      min-width: 320px;
    }
  }
  .illustration-content {
    position: absolute;
    top: 11%;
    right: 42%;
    width: 68%;
    ${media.xs} {
      top: 26%;
    }
  }
  .illustration-content-title {
    position: absolute;
    top: 16%;
    right: 74%;
    width: 10%;
  }
  .illustration-content-desc {
    position: absolute;
    top: 22%;
    right: 66.3%;
    width: 24.4%;
  }
  .illustration-content-logo {
    position: absolute;
    top: 52%;
    right: 72%;
    width: 23%;
    ${media.xs} {
      top: 44%;
    }
  }
  .illustration-content-button {
    position: absolute;
    top: 93%;
    right: 59%;
    width: 38.3%;
    ${media.xs} {
      top: 73%;
    }
  }
  .illustration-content-action {
    position: absolute;
    top: 20%;
    right: -6.2%;
    width: 74%;
    ${media.xs} {
      top: 30%;
    }
  }
  .illustration-content-business {
    position: absolute;
    top: 53%;
    right: -4%;
    width: 68%;
  }
`;

export const Grid = styled(SharedGrid)``;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  padding-bottom: 175px;
  margin-bottom: 202px;
  ${media.md} {
    padding-bottom: 0;
    margin-bottom: 200px;
  }
  ${media.xs} {
    margin-bottom: 100px;
  }
`;
