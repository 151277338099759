import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const TriangleImage = styled.img`
  position: absolute;
  top: -152px;
  left: -192px;
  ${media.md} {
    top: 317px;
    left: -165px;
    height: 268px;
  }
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 1.6;
  color: #838791;
  margin: 0;
  white-space: pre-wrap;
  ${media.sm} {
    font-size: 14px;
    line-height: 1.43;
    white-space: initial;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  font-weight: normal;
  line-height: 1.31;
  margin-bottom: 33px;
  ${media.sm} {
    font-size: 32px;
    line-height: 1.38;
    margin-bottom: 29px;
  }
  strong {
    font-weight: normal;
    color: #5871f4;
  }
`;

export const ContentLeft = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  ${media.lg} {
    padding: 60px 0 0;
  }

  ${media.md} {
    display: flex;
    justify-content: center;
  }

  .illustration-wrapper {
    position: relative;
    height: 100%;
    width: 600px;

    ${media.lg} {
      height: 245px;
      width: 480px;
    }

    ${media.xs} {
      height: 243px;
      width: 320px;
      min-width: 320px;
    }
  }

  .illustration-content {
    position: absolute;
    top: -7%;
    left: -13.2%;
    width: 106%;

    ${media.lg} {
      width: 100%;
      top: -2%;
      left: 0;
    }

    ${media.xs} {
      top: 10%;
    }
  }

  .illustration-content-title {
    position: absolute;
    top: -2%;
    left: 32.5%;
    width: 12%;

    ${media.lg} {
      left: 41.5%;
    }
  }

  .illustration-content-desc {
    position: absolute;
    top: 4%;
    left: 7.5%;
    width: 60%;

    ${media.lg} {
      left: 17.5%;
    }
  }

  .illustration-content-logo {
    position: absolute;
    top: 29%;
    left: 11.7%;
    width: 10.5%;

    ${media.lg} {
      left: 23.7%;
    }

    ${media.xs} {
      top: 22%;
    }
  }

  .illustration-content-rectangle {
    position: absolute;
    top: 23%;
    left: 37.5%;
    width: 37%;

    ${media.lg} {
      left: 47.5%;
      width: 34%;
    }

    ${media.xs} {
      top: 18%;
    }
  }

  .illustration-content-button {
    position: absolute;
    top: 60.3%;
    left: 0;
    width: 34%;

    ${media.lg} {
      left: 12%;
      width: 33%;
    }

    ${media.xs} {
      top: 46.3%;
      left: 12.5%;
      width: 32.5%;
    }
  }

  .illustration-content-action {
    position: absolute;
    top: 79.4%;
    left: 22%;
    width: 62.5%;

    ${media.lg} {
      left: 34%;
    }

    ${media.xs} {
      top: 66.4%;
    }
  }

  .illustration-content-business {
    position: absolute;
    top: 111%;
    left: 30.4%;
    width: 54.3%;

    ${media.lg} {
      left: 42%;
    }

    ${media.xs} {
      top: 91%;
    }
  }
`;

export const ContentRight = styled.div`
  padding-top: 89px;
  padding-left: 80px;
  ${media.md} {
    padding-top: 0;
    padding-left: 0;
  }
`;

export const Grid = styled(SharedGrid)`
  > div {
    ${media.md} {
      flex-direction: column-reverse;
    }
  }
`;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  padding-bottom: 275px;
  margin-bottom: 192px;
  padding-top: 182px;
  ${media.lg} {
    padding-bottom: 0;
    padding-top: 22px;
  }
  ${media.md} {
    padding-top: 50px;
    margin-bottom: 300px;
  }
  ${media.xs} {
    margin-bottom: 180px;
  }
`;
