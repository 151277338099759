import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const TriangleImage = styled.img`
  position: absolute;
  top: -561px;
  right: -299px;
  ${media.md} {
    top: -296px;
    right: -519px;
  }
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 1.6;
  color: #838791;
  margin: 0;
  white-space: pre-wrap;
  ${media.sm} {
    font-size: 14px;
    line-height: 1.43;
    white-space: initial;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  font-weight: normal;
  line-height: 1.31;
  margin-bottom: 38px;
  ${media.sm} {
    font-size: 32px;
    line-height: 1.38;
    margin-bottom: 15px;
  }
  strong {
    font-weight: normal;
    color: #5871f4;
  }
`;

export const ContentLeft = styled.div`
  padding-top: 89px;
  ${media.md} {
    padding-top: 0;
  }
`;

export const ContentRight = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  ${media.lg} {
    padding: 60px 0 0;
  }
  ${media.md} {
    display: flex;
    justify-content: center;
  }
  .illustration-wrapper {
    position: relative;
    height: 100%;
    width: 600px;
    ${media.lg} {
      height: 345px;
      width: 480px;
    }
    ${media.md} {
      height: 325px;
    }
    ${media.xs} {
      height: 243px;
      width: 320px;
      min-width: 320px;
    }
  }
  .illustration-content {
    position: absolute;
    top: -3%;
    right: 2.5%;
    width: 69%;
    ${media.xs} {
      top: 14%;
    }
  }
  .illustration-content-title {
    position: absolute;
    top: 1%;
    right: 33%;
    width: 14%;
  }
  .illustration-content-desc {
    position: absolute;
    top: 5.7%;
    right: 24.5%;
    width: 29.5%;
  }
  .illustration-content-logo {
    position: absolute;
    top: 36.6%;
    right: 32.6%;
    width: 23%;
  }
  .illustration-content-rectangle {
    position: absolute;
    top: 66%;
    right: 20%;
    width: 40%;
  }
  .illustration-content-button {
    position: absolute;
    top: 93%;
    right: 37%;
    width: 6%;
  }
  .illustration-content-action {
    position: absolute;
    top: 22%;
    right: 42%;
    width: 70%;
  }
  .illustration-content-business {
    position: absolute;
    top: 49.5%;
    right: 42.5%;
    width: 65%;
  }
`;

export const Grid = styled(SharedGrid)``;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  padding-top: 137px;
  padding-bottom: 222px;
  margin-bottom: 82px;
  ${media.md} {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 150px;
  }
`;
