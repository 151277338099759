import * as React from 'react';

import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  Inner,
  Grid,
  ContentLeft,
  ContentRight,
  StyledTitle,
  Description,
  BackgroundImageWrapper,
  TriangleTop,
  TriangleTopPhone,
  TriangleBottom,
  TriangleBottomPhone,
  IllustrationWrapper,
  Illustration,
  ItemsBlock,
  ItemWrapper,
  ItemContent,
  ItemImg,
  ImageStatic,
  ItemTitle,
  ItemDescription,
} from './acquisition-awareness.styles';
import ItemIconSvgUrl1 from './images/solution-api.url.svg';
import TriangleSvgUrl2 from './images/solution-bookend-bottom.url.svg';
import TriangleMobileSvgUrl2 from './images/solution-bookend-bottom_phone.url.svg';
import TriangleSvgUrl1 from './images/solution-bookend-top.url.svg';
import TriangleMobileSvgUrl1 from './images/solution-bookend-top_phone.url.svg';
import ItemIconSvgUrl2 from './images/solution-grow.url.svg';
import ItemIconSvgUrl3 from './images/solution-puzzle.url.svg';

export const AwarenessView = ({ title, description, items, illustration }) => (
  <Wrapper>
    <Inner>
      <Grid>
        <ContentLeft>
          <Illustration imgStyle={{ objectFit: 'contain' }} fixed={illustration} />
        </ContentLeft>
        <ContentRight>
          <StyledTitle
            as="h2"
            size="xl"
            dangerouslySetInnerHTML={{
              __html: getTextWithoutParagraph(title),
            }}
          />
          <Description
            dangerouslySetInnerHTML={{
              __html: getTextWithoutParagraph(description),
            }}
          />
          <IllustrationWrapper>
            <Illustration imgStyle={{ objectFit: 'contain' }} fixed={illustration} />
          </IllustrationWrapper>
          <ItemsBlock>
            <ItemWrapper>
              <ItemImg>
                <ImageStatic
                  src={ItemIconSvgUrl1}
                  width={70}
                  height={70}
                  alt=""
                  loading="lazy"
                  aria-hidden
                />
              </ItemImg>
              <ItemContent>
                <ItemTitle as="h3">{items[0].title}</ItemTitle>
                <ItemDescription
                  dangerouslySetInnerHTML={{
                    __html: getTextWithoutParagraph(items[0].description),
                  }}
                />
              </ItemContent>
            </ItemWrapper>
            <ItemWrapper>
              <ItemImg>
                <ImageStatic
                  src={ItemIconSvgUrl2}
                  width={70}
                  height={70}
                  alt=""
                  loading="lazy"
                  aria-hidden
                />
              </ItemImg>
              <ItemContent>
                <ItemTitle as="h3">{items[1].title}</ItemTitle>
                <ItemDescription
                  dangerouslySetInnerHTML={{
                    __html: getTextWithoutParagraph(items[1].description),
                  }}
                />
              </ItemContent>
            </ItemWrapper>
            <ItemWrapper>
              <ItemImg>
                <ImageStatic
                  src={ItemIconSvgUrl3}
                  width={70}
                  height={70}
                  alt=""
                  loading="lazy"
                  aria-hidden
                />
              </ItemImg>
              <ItemContent>
                <ItemTitle as="h3">{items[2].title}</ItemTitle>
                <ItemDescription
                  dangerouslySetInnerHTML={{
                    __html: getTextWithoutParagraph(items[2].description),
                  }}
                />
              </ItemContent>
            </ItemWrapper>
          </ItemsBlock>
        </ContentRight>
      </Grid>
    </Inner>
    <BackgroundImageWrapper>
      <TriangleTop src={TriangleSvgUrl1} />
      <TriangleTopPhone src={TriangleMobileSvgUrl1} />
      <TriangleBottom src={TriangleSvgUrl2} />
      <TriangleBottomPhone src={TriangleMobileSvgUrl2} />
    </BackgroundImageWrapper>
  </Wrapper>
);
