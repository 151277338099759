import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const TriangleImg = styled.img``;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: -405px;
  right: -348px;
  ${media.md} {
    top: -142px;
    right: -479px;
  }
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 1.6;
  color: #838791;
  margin: 0;
  white-space: pre-wrap;
  ${media.sm} {
    font-size: 14px;
    line-height: 1.43;
    white-space: initial;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  font-weight: normal;
  line-height: 1.31;
  margin-bottom: 25px;
  ${media.sm} {
    font-size: 32px;
    line-height: 1.38;
    margin-bottom: 15px;
  }
  strong {
    font-weight: normal;
    color: #5871f4;
  }
`;

export const ContentLeft = styled.div`
  padding-top: 89px;
  ${media.sm} {
    padding-top: 0;
  }
`;

export const ContentRight = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  ${media.lg} {
    padding: 60px 0 0;
  }
  ${media.md} {
    display: flex;
    justify-content: center;
  }
  .illustration-wrapper {
    position: relative;
    height: 100%;
    width: 600px;
    ${media.lg} {
      height: 345px;
      width: 480px;
    }
    ${media.xs} {
      height: 243px;
      width: 320px;
      min-width: 320px;
    }
  }
  .illustration-content {
    position: absolute;
    top: 35px;
    right: 3.3%;
    max-width: 100%;
  }
  .illustration-content-title {
    position: absolute;
    top: 12.2%;
    right: 49.7%;
    width: 11%;
    ${media.xs} {
      top: 5.2%;
    }
  }
  .illustration-content-desc {
    position: absolute;
    top: 17.5%;
    right: 23.6%;
    width: 63%;
    ${media.xs} {
      top: 9.5%;
    }
  }
  .illustration-content-logo {
    position: absolute;
    top: 39.5%;
    right: 70.6%;
    width: 12%;
    ${media.xs} {
      top: 32.5%;
    }
  }
  .illustration-content-rectangle {
    position: absolute;
    top: 30.4%;
    right: 22%;
    width: 37%;
    ${media.xs} {
      top: 23.4%;
    }
  }
  .illustration-content-button {
    position: absolute;
    top: 62.3%;
    right: 62.4%;
    width: 28%;
    ${media.xs} {
      top: 55.3%;
    }
  }
  .illustration-content-action {
    position: absolute;
    top: 40.2%;
    right: -13%;
    width: 69.5%;
  }
  .illustration-content-business {
    position: absolute;
    top: 63.4%;
    right: -10.3%;
    width: 63%;
  }
`;

export const Grid = styled(SharedGrid)``;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  padding-bottom: 175px;
  margin-bottom: 192px;
  ${media.lg} {
    margin-bottom: 92px;
  }
  ${media.sm} {
    margin-bottom: 35px;
  }
`;
