import styled from 'styled-components';

import { media } from 'components/_settings/variables';

export const LogoWrapper = styled.div`
  margin-right: 27px;
  ${media.sm} {
    margin-right: 0;
  }
  &:nth-child(2) {
    margin-right: 15px;
    ${media.sm} {
      margin-right: 0;
    }
    .gatsby-image-wrapper {
      ${media.sm} {
        width: 100px !important;
      }
    }
  }
  &:nth-child(3) {
    margin-right: 4px;
    ${media.sm} {
      margin-right: 0;
    }
  }
  &:last-child {
    margin-right: 0;
    .gatsby-image-wrapper {
      ${media.sm} {
        width: 96px !important;
      }
    }
  }
  .gatsby-image-wrapper {
    ${media.sm} {
      width: 60px !important;
      img {
        object-fit: contain !important;
      }
    }
    ${media.sm} {
      width: 47px !important;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -12px;
  ${media.lg} {
    margin-left: 10px;
  }
  ${media.md} {
    margin-left: 0;
  }
  ${media.sm} {
    width: 100%;
    justify-content: space-between;
    margin-top: 9px;
  }
`;
