import { Link } from 'gatsby';
import * as React from 'react';

import { Button } from 'components/shared/button';
import { GridCell } from 'components/shared/grid';
import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  Inner,
  Head,
  Grid,
  StyledTitle,
  Description,
  ItemWrapper,
  ItemImg,
  ImageStatic,
  ItemTitle,
  ItemDescription,
  ContentFooter,
  FooterLeft,
  FooterTitle,
  FooterText,
  FooterDescription,
  ButtonWrapper,
  ButtonDescription,
  ButtonDescriptionLink,
} from './acquisition-integrate.styles';
import ItemIconSvgUrl2 from './images/solution-api.url.svg';
import ItemIconSvgUrl3 from './images/solution-maintenance.url.svg';
import ItemIconSvgUrl1 from './images/solution-scale.url.svg';
import FooterImgSvgUrl from './images/wegift-blue.url.svg';

export const Integrate = (props) => {
  const {
    title,
    description,
    items,
    bottomTitle,
    bottomDescription,
    bottomButtonText,
    bottomButtonLink,
  } = props;
  return (
    <Wrapper>
      <Inner>
        <Head>
          <StyledTitle as="h2" size="xl">
            {title}
          </StyledTitle>
          <Description>{description}</Description>
        </Head>
        <Grid
          columns={3}
          gap={145}
          media={{
            lg: { gap: 100 },
            md: { columns: 1, gap: 0 },
          }}
        >
          <GridCell>
            <ItemWrapper>
              <ItemImg>
                <ImageStatic
                  src={ItemIconSvgUrl1}
                  width={70}
                  height={70}
                  alt=""
                  loading="lazy"
                  aria-hidden
                />
              </ItemImg>
              <ItemTitle as="h3">{items[0].title}</ItemTitle>
              <ItemDescription
                dangerouslySetInnerHTML={{
                  __html: getTextWithoutParagraph(items[0].description),
                }}
              />
            </ItemWrapper>
          </GridCell>
          <GridCell>
            <ItemWrapper>
              <ItemImg>
                <ImageStatic
                  src={ItemIconSvgUrl2}
                  width={70}
                  height={70}
                  alt=""
                  loading="lazy"
                  aria-hidden
                />
              </ItemImg>
              <ItemTitle as="h3">{items[1].title}</ItemTitle>
              <ItemDescription
                dangerouslySetInnerHTML={{
                  __html: getTextWithoutParagraph(items[1].description),
                }}
              />
            </ItemWrapper>
          </GridCell>
          <GridCell>
            <ItemWrapper>
              <ItemImg>
                <ImageStatic
                  src={ItemIconSvgUrl3}
                  width={70}
                  height={70}
                  alt=""
                  loading="lazy"
                  aria-hidden
                />
              </ItemImg>
              <ItemTitle as="h3">{items[2].title}</ItemTitle>
              <ItemDescription
                dangerouslySetInnerHTML={{
                  __html: getTextWithoutParagraph(items[2].description),
                }}
              />
            </ItemWrapper>
          </GridCell>
        </Grid>
        <ContentFooter>
          <FooterLeft>
            <ImageStatic
              src={FooterImgSvgUrl}
              width={80}
              height={80}
              alt="WeGift Logo"
              loading="lazy"
              aria-hidden
            />
            <FooterText>
              <FooterTitle
                as="h3"
                dangerouslySetInnerHTML={{
                  __html: getTextWithoutParagraph(bottomTitle),
                }}
              />
              <FooterDescription>{bottomDescription}</FooterDescription>
            </FooterText>
          </FooterLeft>
          <ButtonWrapper>
            <Button component={Link} theme="fill" to={bottomButtonLink}>
              {bottomButtonText}
            </Button>
            <ButtonDescription>
              or <ButtonDescriptionLink to="/contact">contact sales</ButtonDescriptionLink>
            </ButtonDescription>
          </ButtonWrapper>
        </ContentFooter>
      </Inner>
    </Wrapper>
  );
};
