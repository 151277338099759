import * as React from 'react';

import CapabilitieSvgUrl1 from '../icons/acqusition.url.svg';
import CapabilitieSvgUrl2 from '../icons/activation.url.svg';
import CapabilitieSvgUrl5 from '../icons/delight.url.svg';
import CapabilitieSvgUrl4 from '../icons/referral.url.svg';
import CapabilitieSvgUrl3 from '../icons/retention.url.svg';

import {
  Wrapper,
  Inner,
  Grid,
  GridItem,
  ItemWrapper,
  ItemImg,
  ImageStatic,
  ItemContent,
  ItemTitle,
  ItemDescription,
  ItemButton,
} from './acquisition-intro-capabilities-block.styles';

export const CapabilitiesBlock = ({ className, items }) => (
  <Wrapper className={className}>
    <Inner>
      <Grid
        columns={5}
        gap={0}
        media={{
          md: { columns: 1 },
        }}
      >
        <GridItem borderRight>
          <ItemWrapper href={`#${items[0].buttonLink}`}>
            <ItemImg>
              <ImageStatic src={CapabilitieSvgUrl1} width={70} height={70} alt="" loading="lazy" />
            </ItemImg>
            <ItemContent>
              <ItemTitle as="h4">{items[0].title}</ItemTitle>
              <ItemDescription>{items[0].description}</ItemDescription>
              <ItemButton>{items[0].buttonText}</ItemButton>
            </ItemContent>
          </ItemWrapper>
        </GridItem>
        <GridItem borderRight>
          <ItemWrapper href={`#${items[1].buttonLink}`}>
            <ItemImg>
              <ImageStatic src={CapabilitieSvgUrl2} width={70} height={70} alt="" loading="lazy" />
            </ItemImg>
            <ItemContent>
              <ItemTitle as="h4">{items[1].title}</ItemTitle>
              <ItemDescription>{items[1].description}</ItemDescription>
              <ItemButton>{items[0].buttonText}</ItemButton>
            </ItemContent>
          </ItemWrapper>
        </GridItem>
        <GridItem borderRight>
          <ItemWrapper href={`#${items[2].buttonLink}`}>
            <ItemImg>
              <ImageStatic src={CapabilitieSvgUrl3} width={70} height={70} alt="" loading="lazy" />
            </ItemImg>
            <ItemContent>
              <ItemTitle as="h4">{items[2].title}</ItemTitle>
              <ItemDescription>{items[2].description}</ItemDescription>
              <ItemButton>{items[0].buttonText}</ItemButton>
            </ItemContent>
          </ItemWrapper>
        </GridItem>
        <GridItem borderRight>
          <ItemWrapper href={`#${items[3].buttonLink}`}>
            <ItemImg>
              <ImageStatic src={CapabilitieSvgUrl4} width={70} height={70} alt="" loading="lazy" />
            </ItemImg>
            <ItemContent>
              <ItemTitle as="h4">{items[3].title}</ItemTitle>
              <ItemDescription>{items[3].description}</ItemDescription>
              <ItemButton>{items[0].buttonText}</ItemButton>
            </ItemContent>
          </ItemWrapper>
        </GridItem>
        <GridItem>
          <ItemWrapper href={`#${items[4].buttonLink}`}>
            <ItemImg>
              <ImageStatic src={CapabilitieSvgUrl5} width={70} height={70} alt="" loading="lazy" />
            </ItemImg>
            <ItemContent>
              <ItemTitle>{items[4].title}</ItemTitle>
              <ItemDescription>{items[4].description}</ItemDescription>
              <ItemButton>{items[0].buttonText}</ItemButton>
            </ItemContent>
          </ItemWrapper>
        </GridItem>
      </Grid>
    </Inner>
  </Wrapper>
);
