import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const TriangleImage = styled.img`
  position: absolute;
  top: -38px;
  left: -313px;
  ${media.sm} {
    top: 12px;
    left: -313px;
    height: 395px;
    height: 575px;
  }
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 1.6;
  color: #838791;
  margin: 0;
  white-space: pre-wrap;
  ${media.sm} {
    font-size: 14px;
    line-height: 1.43;
    white-space: initial;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  font-weight: normal;
  line-height: 1.31;
  margin-bottom: 33px;
  ${media.sm} {
    font-size: 32px;
    line-height: 1.38;
    margin-bottom: 5px;
  }
  strong {
    font-weight: normal;
    color: #5871f4;
  }
`;

export const ContentLeft = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  ${media.lg} {
    padding: 60px 0 0;
  }
  ${media.md} {
    display: flex;
    justify-content: center;
  }
  .illustration-wrapper {
    position: relative;
    height: 100%;
    width: 600px;
    ${media.lg} {
      height: 326px;
      width: 560px;
    }
    ${media.xs} {
      width: 320px;
      min-width: 320px;
    }
  }
  .illustration-content {
    position: absolute;
    top: 4%;
    left: -6%;
    max-width: 100%;
  }
  .illustration-content-title {
    position: absolute;
    top: 6.7%;
    left: 5.3%;
    width: 6%;
    ${media.xs} {
      top: -3%;
    }
  }
  .illustration-content-desc {
    position: absolute;
    top: 13%;
    left: 5.4%;
    width: 55%;
    ${media.xs} {
      top: 1%;
    }
  }
  .illustration-content-logo {
    position: absolute;
    top: 40%;
    left: 24.4%;
    width: 17%;
    ${media.xs} {
      top: 19%;
      left: 34%;
    }
  }
  .illustration-content-rectangle {
    position: absolute;
    top: 66%;
    left: 7%;
    width: 25%;
    ${media.xs} {
      display: none;
    }
  }
  .illustration-content-button {
    position: absolute;
    top: 100%;
    left: 6%;
    width: 55%;
    ${media.xs} {
      top: 58%;
      left: 9%;
    }
  }
  .illustration-content-action {
    position: absolute;
    top: 51.5%;
    left: 40%;
    width: 67.6%;
    ${media.xs} {
      top: 37.5%;
    }
  }
  .illustration-content-business {
    position: absolute;
    top: 90%;
    left: 43%;
    width: 56%;
    ${media.xs} {
      top: 56%;
    }
  }
`;

export const ContentRight = styled.div`
  padding-top: 89px;
  padding-left: 80px;
  ${media.md} {
    padding-top: 0;
    padding-left: 0;
  }
`;

export const Grid = styled(SharedGrid)`
  > div {
    ${media.md} {
      flex-direction: column-reverse;
    }
  }
`;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  padding-bottom: 175px;
  margin-bottom: 192px;
  ${media.md} {
    padding-bottom: 0;
    margin-bottom: 250px;
  }
  ${media.xs} {
    margin-bottom: 100px;
  }
`;
