import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from 'components/_settings/layout';
import { Footer } from 'components/blocks/footer';
import { Header } from 'components/blocks/header';
import { SolutionsBanner } from 'components/blocks/solutions-banner';
import { Trusted } from 'components/blocks/trusted';
import { Acquisition } from 'components/pages/acquisition/acquisition';
import { Activation } from 'components/pages/acquisition/activation';
import { Awareness } from 'components/pages/acquisition/awareness';
import { Delight } from 'components/pages/acquisition/delight';
import { Integrate } from 'components/pages/acquisition/integrate';
import { Intro } from 'components/pages/acquisition/intro';
import { Referral } from 'components/pages/acquisition/referral';
import { Retention } from 'components/pages/acquisition/retention';

import { buildPageMetadata } from '../utils/data-builders';
import { extractItems } from '../utils/extract-items';

const AcquisitionNew = (props) => {
  const {
    data: {
      wpPage,
      wpPage: { acf },
    },
  } = props;

  const capabilityItems = extractItems(acf, 'section1Item');
  const trustedItems = extractItems(acf, 'section7Item');
  const awarenessItems = extractItems(acf, 'section8Item');
  const integrateItems = extractItems(acf, 'section9Item');

  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      <Header />
      <Intro
        labelText={acf.section1TextAboveTitle}
        title={acf.section1Title}
        buttonText={acf.section1ButtonText}
        buttonLink={acf.section1ButtonLink}
        bottomText={acf.section1BottomText}
        logos={acf.section1Logos}
        capabilities={capabilityItems}
      />
      <Acquisition title={acf.section2Title} description={acf.section2Description} />
      <Activation title={acf.section3Title} description={acf.section3Description} />
      <Retention title={acf.section4Title} description={acf.section4Description} />
      <SolutionsBanner
        title={acf.section4BannerTitle}
        description={acf.section4BannerDescription}
        buttonText={acf.section4BannerButtonText}
        buttonLink={acf.section4BannerButtonLink}
      />
      <Referral title={acf.section5Title} description={acf.section5Description} />
      <Delight title={acf.section6Title} description={acf.section6Description} />
      <Trusted
        title={acf.section7Title}
        description={acf.section7Description}
        image={acf.section7Image}
        imageLogo={acf.section7ImageLogo}
        buttonText={acf.section7ButtonText}
        buttonLink={acf.section7ButtonLink}
        items={trustedItems}
        bottomTitle={acf.section7BottomTitle}
        bottomDescription={acf.section7BottomDescription}
        bottomButtonText={acf.section7BottomButtonText}
        bottomButtonLink={acf.section7BottomButtonLink}
      />
      <Awareness
        title={acf.section8Title}
        description={acf.section8Description}
        items={awarenessItems}
      />
      <Integrate
        title={acf.section9Title}
        description={acf.section9Description}
        items={integrateItems}
        bottomTitle={acf.section9BottomTitle}
        bottomDescription={acf.section9BottomDescription}
        bottomButtonText={acf.section9BottomButtonText}
        bottomButtonLink={acf.section9BottomButtonLink}
      />
      <Footer theme="light" />
    </Layout>
  );
};

export default AcquisitionNew;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      acf {
        showHeader
        showFooter

        section1TextAboveTitle
        section1Title
        section1Description
        section1ButtonText
        section1ButtonLink
        section1BottomText
        section1Logos {
          id
          altText
          localFile {
            childImageSharp {
              fluid {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section1Item1Title
        section1Item1Description
        section1Item1ButtonText
        section1Item1ButtonLink
        section1Item2Title
        section1Item2Description
        section1Item2ButtonText
        section1Item2ButtonLink
        section1Item3Title
        section1Item3Description
        section1Item3ButtonText
        section1Item3ButtonLink
        section1Item4Title
        section1Item4Description
        section1Item4ButtonText
        section1Item4ButtonLink
        section1Item5Title
        section1Item5Description
        section1Item5ButtonText
        section1Item5ButtonLink
        section2Title
        section2Description
        section3Title
        section3Description
        section4Title
        section4Description
        section4BannerTitle
        section4BannerDescription
        section4BannerButtonText
        section4BannerButtonLink
        section5Title
        section5Description
        section6Title
        section6Description
        section7Title
        section7Description
        section7Image {
          altText
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section7ImageLogo {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 280) {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section7ButtonText
        section7ButtonLink
        section7Item1Logo {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 150) {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section7Item1Title
        section7Item1BottomText
        section7Item2Logo {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 200) {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section7Item2Title
        section7Item2BottomText
        section7Item3Logo {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 120) {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section7Item3Title
        section7Item3BottomText
        section7Item4Logo {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 150) {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section7Item4Title
        section7Item4BottomText
        section7BottomTitle
        section7BottomDescription
        section7BottomButtonText
        section7BottomButtonLink
        section8Title
        section8Description
        section8Item1Title
        section8Item1Description
        section8Item2Title
        section8Item2Description
        section8Item3Title
        section8Item3Description
        section9Title
        section9Description
        section9Item1Title
        section9Item1Description
        section9Item2Title
        section9Item2Description
        section9Item3Title
        section9Item3Description
        section9BottomTitle
        section9BottomDescription
        section9BottomButtonText
        section9BottomButtonLink
      }
    }
  }
`;
