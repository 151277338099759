import styled from 'styled-components';

import { color, media, fontSize, transition } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';
import { Link } from 'components/shared/link';
import { Title } from 'components/shared/title';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonDescriptionLink = styled(Link)`
  text-decoration: none;
  color: #838791;
  transition: ${transition.base};
  &:hover {
    color: ${color.primary};
  }
`;

export const ButtonDescription = styled.span`
  font-size: 14px;
  line-height: 2;
  color: #838791;
  margin-top: 14px;
  ${media.md} {
    margin-top: 17px;
  }
`;

export const ContentFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 47px 67px 41px 54px;
  border-radius: 8px;
  box-shadow: 14px 36px 150px -45px rgba(50, 50, 93, 0.5), 2px 2px 12px 0 rgba(26, 26, 67, 0.05);
  background-color: ${color.text.tertiary} ${media.md} {
    flex-direction: column;
    justify-content: center;
  }
  ${media.md} {
    flex-direction: column;
    justify-content: center;
    padding: 47px 29px 64px 29px;
  }
`;

export const FooterLeft = styled.div`
  display: flex;
  align-items: center;
  ${media.md} {
    flex-direction: column;
  }
`;

export const FooterText = styled.div`
  margin-left: 40px;
  ${media.lg} {
    max-width: 396px;
  }
  ${media.md} {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 100%;
    margin-left: 0;
    margin-top: 20px;
    margin-bottom: 43px;
  }
`;

export const FooterTitle = styled(Title)`
  font-size: ${fontSize.lg};
  font-weight: normal;
  line-height: 1.33;
  margin-bottom: 12px;
  ${media.md} {
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 34px;
    text-align: center;
  }
  strong {
    font-weight: normal;
    color: #19c7e1;
  }
`;

export const FooterDescription = styled.p`
  font-size: 18px;
  line-height: 1.56;
  color: #838791;
  margin: 0;
  ${media.md} {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
  }
`;

export const ItemWrapper = styled.div`
  ${media.md} {
    text-align: center;
    margin-bottom: 54px;
  }
`;

export const ItemImg = styled.div`
  margin-bottom: 12px;
`;

export const ImageStatic = styled.img``;

export const ItemTitle = styled(Title)`
  font-size: 20px;
  line-height: 1.8;
  margin-bottom: 3px;
  ${media.sm} {
    font-size: 20px;
    line-height: 1.8;
  }
`;

export const ItemDescription = styled.p`
  font-size: 18px;
  line-height: 1.56;
  color: #838791;
  margin: 0;
`;

export const Description = styled.p`
  font-size: 40px;
  font-weight: 300;
  color: #667adc;
  margin: 0;
  white-space: pre-wrap;
  ${media.sm} {
    font-size: 20px;
    line-height: 2;
    text-align: center;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 40px;
  line-height: 1.48;
  margin-bottom: 2px;
  ${media.sm} {
    font-size: 32px;
    line-height: 1.25;
    text-align: center;
    margin-bottom: 8px;
  }
`;

export const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
  ${media.md} {
    margin-bottom: 27px;
  }
`;

export const Grid = styled(SharedGrid)`
  padding: 0 68px;
  margin-bottom: 136px;
  ${media.md} {
    padding: 0 38px;
    margin-bottom: 0;
  }
`;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  padding-bottom: 85px;
  margin-bottom: 19px;
  ${media.sm} {
    padding-bottom: 47px;
    margin-bottom: 0;
  }
`;
