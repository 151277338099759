import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import * as React from 'react';

import { RevealTextFromTop, FadeInBottom, FadeInBottomS } from 'animations';
import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  Inner,
  Content,
  Label,
  StyledLabelIcon,
  LabelText,
  StyledTitle,
  StyledButton,
  BottomWrapper,
  BottomText,
  StyledIllustration,
  TriangleWrapper,
  TriangleSvg,
} from './acquisition-intro.styles';
import { CapabilitiesBlock } from './capabilities-block';
import LabelIconSvgUrl from './icons/acquisiton-color.url.svg';
import TriangleSvgUrl from './images/solution-top-triangle.url.svg';
import IllustrationSvgUrl from './images/solutions-acquisition-outline.url.svg';
import { LogosBlock } from './logos-block';

const containerAnimationVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

export const Intro = (props) => {
  const { labelText, title, buttonLink, buttonText, bottomText, logos, capabilities } = props;
  return (
    <Wrapper>
      <Inner>
        <Content>
          <motion.div initial="hidden" animate="visible" variants={containerAnimationVariants}>
            <motion.div variants={FadeInBottomS}>
              <Label>
                <StyledLabelIcon src={LabelIconSvgUrl} width={32} height={33} alt="" />
                <LabelText>{labelText}</LabelText>
              </Label>
            </motion.div>
            <StyledTitle size="xxl">
              {getTextWithoutParagraph(title)
                .split('<br />')
                .map((part, index) => (
                  <motion.div
                    key={`intro-title-${index}`}
                    variants={RevealTextFromTop}
                    dangerouslySetInnerHTML={{ __html: part }}
                  />
                ))}
            </StyledTitle>
            <motion.div variants={FadeInBottom}>
              <StyledButton component={Link} theme="fill" to={buttonLink}>
                {buttonText}
              </StyledButton>
            </motion.div>
          </motion.div>
          <StyledIllustration src={IllustrationSvgUrl} />
          <BottomWrapper>
            <LogosBlock items={logos} />
            <BottomText>{bottomText}</BottomText>
          </BottomWrapper>
        </Content>
      </Inner>
      <CapabilitiesBlock items={capabilities} />
      <TriangleWrapper>
        <TriangleSvg src={TriangleSvgUrl} />
      </TriangleWrapper>
    </Wrapper>
  );
};
