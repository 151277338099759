import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';

import { media, color } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const ItemsBlock = styled.div``;

export const ItemWrapper = styled.div`
  display: flex;
  margin-bottom: 33px;
`;

export const ItemContent = styled.div`
  margin-left: 37px;
`;

export const ItemImg = styled.div`
  svg {
    ${media.sm} {
      width: 70px;
    }
  }
`;

export const ImageStatic = styled.img``;

export const ItemTitle = styled(Title)`
  font-size: 20px;
  line-height: 1.8;
  color: ${color.text.tertiary};
  margin-bottom: 3px;
  ${media.sm} {
    font-size: 20px;
  }
`;

export const ItemDescription = styled.p`
  font-size: 18px;
  line-height: 1.56;
  color: #838791;
  margin: 0;

  ${media.md} {
    br {
      display: none;
    }
  }
`;

export const IllustrationWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
  display: none;
  ${media.md} {
    display: block;
  }
`;

export const Illustration = styled(GatsbyImage)`
  top: 51px;
  left: -33px;
  ${media.lg} {
    top: 140px;
    left: 0;
    width: 100% !important;
  }
  ${media.md} {
    top: 0;
    left: 0;
    width: 60% !important;
    display: block !important;
    margin-left: auto;
    margin-right: auto;
  }
  ${media.sm} {
    width: 100% !important;
    max-height: 460px;
  }
`;

export const TriangleTop = styled.img`
  position: absolute;
  top: -88px;
  left: 50%;
  width: 100%;
  min-width: 1920px;
  transform: translateX(-50%);
  @media (max-width: 375px) {
    display: none;
  }
`;

export const TriangleTopPhone = styled.img`
  position: absolute;
  top: -88px;
  left: 0;
  display: none;
  @media (max-width: 375px) {
    display: block;
  }
`;

export const TriangleBottom = styled.img`
  position: absolute;
  bottom: -88px;
  left: 50%;
  width: 100%;
  min-width: 1920px;
  transform: translateX(-50%);
  @media (max-width: 375px) {
    display: none;
  }
`;

export const TriangleBottomPhone = styled.img`
  position: absolute;
  bottom: -88px;
  left: 0;
  display: none;
  @media (max-width: 375px) {
    display: block;
  }
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  @media (min-width: 1921px) {
    display: none;
  }
`;

export const Description = styled.p`
  font-size: 32px;
  font-weight: 300;
  line-height: 1.84;
  color: #8096ff;
  margin: 0 0 60px;
  ${media.sm} {
    font-size: 24px;
    line-height: 1.33;
    margin: 0 0 26px;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 40px;
  line-height: 1.48;
  color: ${color.text.tertiary};
  white-space: pre-wrap;
  margin-bottom: 14px;
  ${media.lg} {
    white-space: initial;
  }
  ${media.sm} {
    font-size: 32px;
    font-weight: normal;
    line-height: 1.25;
    margin-bottom: 17px;
  }
`;

export const ContentLeft = styled.div`
  position: relative;
  ${media.md} {
    display: none;
  }
`;

export const ContentRight = styled.div`
  padding-top: 129px;
  padding-left: 45px;
  ${media.md} {
    grid-row-start: 1;
    padding-top: 81px;
    padding-left: 0;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 675px;
  ${media.lg} {
    grid-template-columns: 1fr 1fr;
  }
  ${media.md} {
    grid-template-columns: 12fr;
  }
`;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  margin-top: 157px;
  padding-bottom: 180px;
  margin-bottom: 308px;
  background-color: #211e51;
  ${media.lg} {
    padding-bottom: 70px;
    margin-bottom: 195px;
  }
  ${media.md} {
    padding-bottom: 25px;
    margin-bottom: 140px;
  }
`;
