import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { AwarenessView } from './acquisition-awareness.view';

export const Awareness = (props) => {
  const {
    illustration: {
      childImageSharp: { fixed: illustrationFixed },
    },
  } = useStaticQuery(graphql`
    query {
      illustration: file(
        relativePath: { eq: "pages/customer-acquisition/awareness/solution-diagram@2x.png" }
      ) {
        childImageSharp {
          fixed(width: 492) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);

  return <AwarenessView {...props} illustration={illustrationFixed} />;
};
